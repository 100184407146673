var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-button",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.show,
          expression: "show",
        },
      ],
      staticClass: "secondary-button",
      attrs: {
        disabled: _vm.disabled,
        variant: "default",
        "aria-label": _vm.buttonText + " button",
      },
      on: {
        click: function ($event) {
          return _vm.$emit("onclick")
        },
      },
    },
    [
      _vm.loading
        ? _c("b-spinner", { attrs: { small: "", variant: "light" } })
        : _c("span", { staticStyle: { padding: "0 0.5rem" } }, [
            _vm._v("\n    " + _vm._s(_vm.buttonText) + "\n  "),
          ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }