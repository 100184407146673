<template>
  <b-button
    v-show="show"
    class="secondary-button"
    :disabled="disabled"
    variant="default"
    :aria-label="buttonText + ' button'"
    @click="$emit('onclick')"
  >
    <b-spinner v-if="loading" small variant="light" />

    <span v-else style="padding: 0 0.5rem">
      {{ buttonText }}
    </span>
  </b-button>
</template>

<script>
export default {
  name: 'SecondaryButton',
  props: {
    buttonText: {
      type: String,
      default: 'Continue',
    },
    show: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
.secondary-button {
  border: 1px $ct-ui-primary solid !important;
  border-radius: $stageline-primary-border-radius;
}
</style>
